import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { PATH } from "~/constant/path";
import { ArticleHeader } from "~/molecules/ArticleHeader";
import { ArticleFooter } from "~/molecules/ArticleFooter";
import { BaseArticleBody } from "~/molecules/BaseArticleBody";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { ArticleCards } from "~/molecules/ArticleCards";
import { RecommendedArticles } from "~/molecules/RecommendedArticles";
import { Container } from "~/atoms/Container";
import { ArticleContent } from "~/organisms/ArticleContent";
import { ArticleSidebar } from "~/organisms/ArticleSidebar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  pageContext: {
    contentfulId: string;
    regex: string;
  };
  path: string;
  data: {
    article: GatsbyTypes.ContentfulReport;
    otherReportArticles: GatsbyTypes.ContentfulReportConnection;
    recommendCases: GatsbyTypes.ContentfulCaseConnection;
    recommendReports: GatsbyTypes.ContentfulReportConnection;
  };
};

const ArticleReport: React.FC<Props> = ({ path, data }) => {
  const { article, otherReportArticles, recommendCases, recommendReports } = data;
  const breadCrumbItems = [
    PATH.TOP,
    PATH.EVENTS,
    PATH.EVENTS_REPORT,
    {
      name: article.title,
    },
  ];
  const sharedOgpTitle = article?.ogpTitle || ``;
  const sharedOgpDescription = article?.ogpDescription?.ogpDescription || ``;

  const reportArticles = otherReportArticles.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/report/${edge.node.contentful_id}`,
    };
  });

  const recommendArticles = [
    ...recommendCases.edges.map((edge) => {
      return {
        node: edge.node,
        href: `/cases/${edge.node.contentful_id}`,
      };
    }),
    ...recommendReports.edges.map((edge) => {
      const baseHref = `/events/${edge.node.eventCategory?.slug}/`;

      return {
        node: edge.node,
        href:
          edge.node.eventCategory?.slug === "award"
            ? `${baseHref}interview${edge.node.awardYear}/`
            : `${baseHref}${edge.node.contentful_id}`,
      };
    }),
  ];

  return (
    <>
      <style jsx>{`
        .articleWrapper {
          background-color: ${colors.creamGray};
        }

        .articleWrapper :global(.container) {
          padding: 30px 0 50px;
        }

        @media (${breakPoints.sp}) {
          .articleWrapper :global(.container) {
            flex-direction: column;
            padding: 0 0 50px;
          }
        }
      `}</style>
      <Layout
        title={article.title || ``}
        ogpDescription={sharedOgpDescription}
        ogpImageUrl={(article.ogpImage && `https:${article!.ogpImage!.fluid!.src}`) || undefined}
        ogpTitle={sharedOgpTitle}
        breadCrumbItems={breadCrumbItems}
        breadCrumbBgColor={colors.creamGray2}
      >
        <div className="articleWrapper">
          <Container>
            <ArticleContent>
              <ArticleHeader categoryName={PATH.EVENTS_REPORT.name} title={article.title || ``} date={article.date} />
              <BaseArticleBody
                content={article.content}
                ogpImageSrc={article!.ogpImage!.fluid!.src}
                ogpImageAspect={article!.ogpImage!.fluid!.aspectRatio}
              />
              <ArticleFooter url={path} title={article.title || ``} ogpTitle={sharedOgpTitle} />
            </ArticleContent>
            <ArticleSidebar articles={reportArticles} category={PATH.EVENTS_REPORT.name} />
          </Container>
        </div>
        <ArticleListSection title={PATH.EVENTS_REPORT.name}>
          <ArticleCards articles={reportArticles} prefix="ReportArticles" />
        </ArticleListSection>
        <ArticleListSection title="こちらもおすすめ">
          <RecommendedArticles articles={recommendArticles} />
        </ArticleListSection>
      </Layout>
    </>
  );
};

export default ArticleReport;

export const articleReportQuery = graphql`
  query ArticleReport($contentfulId: String, $regex: String) {
    article: contentfulReport(contentful_id: { eq: $contentfulId }) {
      contentful_id
      title
      date
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              url
            }
          }
          ... on ContentfulPersonIntroduction {
            __typename
            id
            contentful_id
            name
            facePhotograph {
              file {
                url
              }
            }
            company
            position
            childContentfulPersonIntroductionDescriptionTextNode {
              description
            }
          }
        }
      }
      eventCategory {
        slug
        name
      }
      ogpTitle
      ogpDescription {
        internal {
          content
        }
      }
      ogpImage {
        fluid(maxWidth: 900, quality: 100) {
          src
          aspectRatio
        }
      }
    }
    otherReportArticles: allContentfulReport(
      filter: { contentful_id: { ne: $contentfulId }, eventCategory: { slug: { eq: "report" } } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          date
        }
      }
    }
    recommendCases: allContentfulCase(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          company {
            name
          }
          date
        }
      }
    }
    recommendReports: allContentfulReport(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          companyName
          awardYear
          eventCategory {
            slug
          }
          date
        }
      }
    }
  }
`;
